@import "~bootstrap/scss/mixins";
@import 'variables';

.form {
  box-shadow: $box-shadow;
  background-color: $white;
  padding: $spacer;
  .form-title {
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1;
    padding-bottom: $spacer;
    margin-bottom: $spacer * 1.5;
    border-bottom: 1px solid $gray-200;
  }
}

.associated-grants{
  margin-top: 20px;
}

input[type='date'] {
  position: relative;
  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 12px;
      right: $spacer / 2;
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
      width: 14px;
      height: 18px;
      cursor: pointer;
      border-radius: 50%;
  }

  &::-webkit-calendar-picker-indicator:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, .04);
  }
}

.form-group-invalid {
  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      right: 2 * $spacer;
    }
  }
}

.reset-link {
  display: inline-flex;
  align-items: center;
  > i {
    margin-right: $spacer * 0.33
  }
}