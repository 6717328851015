@import "~bootstrap/scss/mixins";
@import 'variables';

.responsive-table {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($breakpoint) {
        .btn-toolbar {
          justify-content: flex-end;
        }
      }
      @include media-breakpoint-down($breakpoint) {
        &, thead, tbody, tfoot, tr, th, td {
          display: block
        }

        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tbody, tfoot {
          th, td {
            border: none;
          }
        }

        tbody {
          tr {
            border: 1px solid $border-color;
            margin-bottom: $spacer / 4;
          }
          td {
            padding: $table-cell-padding-sm $table-cell-padding;
            & + td {
              border-top: 1px dotted $border-color;
            }
            &:before {
              content: attr(data-label);
              font-weight: 600;
              margin-bottom: $spacer / 4;
              display: block;
            }
          }
        }


      }
    }
  }
}
