@import 'variables';

.table {
  > thead > tr:first-child {
    th {
      border-top: none;
    }
  }

  .btn-toolbar {
    flex-wrap: nowrap;
    .btn {
      white-space: nowrap;
    }
    .btn + .btn {
      margin-left: $spacer / 2;
    }
    &.vertical-toolbar {
      .btn + .btn {
        margin-left: 0;
        margin-top: $spacer / 2;
      }
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
