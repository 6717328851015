$input-padding-y: 0.75rem;
$input-padding-x: 0.75rem;

@import 'variables';

.form-label-group {
  position: relative;
  margin-bottom: $spacer;
  &.select {
    &::before {
      position: absolute;
      content: '';
      top: 22px;
      right: $spacer;
      border: 6px solid transparent;
      border-top-color: $gray-600;
    }
    &.form-group-invalid {
      &::before {
        right: 2 * $spacer;
      }
    }
  }
  > {
    label {
      font-size: 0.75rem;
      color: darken($primary, 10%);
      font-weight: 600;
      padding: calc(#{$input-padding-y} / 3) $input-padding-x;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      transition: all .1s ease-in-out;
      pointer-events: none;
    }

    select.form-control {
      appearance: none;
      padding-right: calc(#{$input-padding-x} * 2 + 12px);
    }

    textarea.form-control, input.form-control, select.form-control {
      padding-top: calc(#{$input-padding-y} + #{$input-padding-y} * (2 / 3));
      padding-bottom: calc(#{$input-padding-y} / 3);
      &::placeholder {
        opacity: 0;
        color: transparent;
      }
    }

    input.form-control, select.form-control {
      height: $input-height;
    }

    input.form-control:placeholder-shown, textarea.form-control:placeholder-shown, select.form-control:not(:focus):not(:valid):not(:disabled) {
      padding: $input-padding-y $input-padding-x;
      ~ label {
        padding: $input-padding-y $input-padding-x;
        font-size: $input-font-size;
        font-weight: 400;
        color: $input-color;
      }
    }

  }
}

.custom-file {
  &, .custom-file-input, label {
    height: $input-height;
    overflow: hidden;
  }

  label {
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    font-weight: 400;
    color: $input-color;
    &:after {
      display: flex;
      align-items: center;
      height: 3rem;
    }
  }
}

.input-group {
  flex-wrap: nowrap;
  margin-bottom: $spacer;
  .input-group-text {
    height: $input-height;
    align-self: flex-start;
  }
  .input-group-text.custom-control {
    display: flex;
    padding-left: 0.75rem;

    .custom-control-label {
      min-height: $input-line-height * $input-btn-font-size;
      min-width: 1rem;
      &:before, &:after {
        left: 0;
      }
    }
  }

  .input-group-append {
    height: $input-height;
    align-self: flex-start;
    &:last-child > .btn {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  .form-label-group {
    margin-bottom: 0;
    flex-grow: 1;
    flex-direction: column;

    &:last-child > .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child > .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}


.col, [class*="col-"] {
  &.form-label-group, &.form-group {
    label {
      left: 15px;
    }
  }
}

.edit_growth_run {
  button {
    height: $input-height;
  }
}
