@import 'variables';

.flash-messages {
  .alert {
    margin-top: 1px;
    margin-bottom: 0px;
    background-color: #F5D65D;
    
  }
}
