@import "~bootstrap/scss/mixins";
@import 'variables';

.ended-grant {
  opacity: 0.5;
}

.grant-card {
  border: 1px solid $border-color;
  position: relative;
  padding-left: 4px;
  margin-bottom: $spacer * 2;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 5px;
    background-color: $primary;
  }

  .card-title {
    display: flex;
    margin: 0;
    align-items: flex-start;

    .grant-details {

      flex: 1;
      ul {
        li + li {
           margin-left: $spacer * 2;
        }
      }
    }



    h2 {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  .card-title, .card-details {
    padding: $spacer;
  }

  table {
    margin-bottom: 0;
  }
}
