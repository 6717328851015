@import "~bootstrap/scss/mixins";
@import 'variables';

#login-box {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-shadow: $box-shadow-sm;
  border-radius: 10px;

  #login-title {
    white-space: nowrap;
    display: flex;
    padding: $spacer;
    background-color: $primary;
    align-items: center;
    h1 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
      color: #fff;
    }
  }

  .devise-form {
    background-color: $gray-100;
    padding: $spacer;

    h2 {
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
      margin-bottom: $spacer * 2;
      small {
        margin-top: $spacer / 2;
        display: block;
        font-size: 1rem;
        color: $gray-600;
      }
    }

    .actions {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #login-button {
      display: block;
      width: 100%;
      margin-bottom: $spacer;
    }
  }

  @media(min-width: #{map-get($grid-breakpoints, md)}) {
    box-shadow: $box-shadow;

    #login-title {
      border-right: 1px solid darken($primary, 10%);
      h1 {
        font-size: 1.4rem;
      }
    }
  }

  @media(min-width: #{map-get($grid-breakpoints, lg)}) {
    margin-top: 3 * $spacer;
    flex-direction: row;

    #login-title {
      flex: 1;
      h1 {
        margin-bottom: $spacer * 2;
      }
    }

    .devise-form {
      flex: 2;
      padding: 2 * $spacer;
      .actions {
        flex-direction: row;
        padding-top: $spacer;
      }
      #login-button {
        display: inline-block;
        width: auto;
        margin-bottom: 0;
      }
    }
  }

}
