@import '../variables';

form + .documents {
  margin-top: $spacer * 1.5;
}

.document {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
