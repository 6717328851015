@import "~bootstrap/scss/mixins";
@import 'variables';

#growth-request {
  #request-status {
    padding: 0;
    list-style: none;
    display: flex;

    li {
      flex: 1;
      text-align: center;
      background-color: $gray-200;
      padding: $spacer / 2 $spacer;

      border-right: 2px solid $white;

      &.active {
        background-color: $link-hover-color;
        color: $white;
        font-weight: 800;
        position: relative;
        &:before, &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -10px;
          border: 10px solid transparent;
        }

        &:before {
          right: -20px;
          border-left-color: $white;
        }

        &:after {
          right: -18px;
          border-left-color: $link-hover-color;
        }


      }
    }
  }
}
